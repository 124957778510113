

import React from 'react'
import SeoPage from '../components/SeoPage'
import UpperForm from '../components/UpperForm'
import BottomForm from '../components/BottomForm'
import { StaticImage } from 'gatsby-plugin-image'
import LeadImage from '../images/raisons-avantages-consulter-avocat-legal-blainville.jpeg'
import { graphql } from 'gatsby'
import ConversionLink from '../components/ConversionLink'


const ContentPage7 = ({ data, location, ...props }) => (
    <SeoPage location={location}
        title="Les raisons d’aller consulter les meilleurs avocats à Blainville! - Soumissions Avocat"
        description="Ciblez l'avocat qu'il vous faut à Blainville pour défendre vos affaires, vos litiges en immobilier, vos disputes contractuelles ou vos accusations criminelles ou pénales. Votre représentation en cour par un spécialiste juridique tel qu'un avocat à Blainville est une nécessité. Trouvez le meilleur pour vous ici."
        image={LeadImage}>
        <h1>Les raisons d’aller consulter les meilleurs avocats à Blainville!</h1><p>Quand une partie de votre corps vous fait souffrir, vous ne vous cassez pas la tête longtemps et vous allez voir un médecin! Ce sage réflexe évite bien des problématiques et empêche un problème autrement bénin de s’aggraver. Le même réflexe est tout aussi judicieux lorsque vous éprouvez un problème légal menaçant de dégénérer et de se transformer en litige. Que vous intentions soit des plus nobles n’a que très peu d’incidence sur vos chances de vous retrouver avec un tel problème.</p>
<p><StaticImage alt="raisons-avantages-consulter-avocat-legal-blainville" src="../images/raisons-avantages-consulter-avocat-legal-blainville.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>En effet, même les personnes les plus prudentes et diligentes ne sont pas à l’abri d’une poursuite ou d’un important désaccord. L’important, c’est de reconnaître l’urgence de la situation de ne pas tarder à consulter un avocat! Ces derniers sont des professionnels hautement qualifiés qui sauront régler vos litiges rapidement et efficacement. Voyez donc ce que l’un d’entre eux peut faire pour vous à Blainville!</p>
<h2>Les responsabilités d’un avocat envers son client!</h2>
<p>Lorsque vous engagez un avocat, vous entrez dans une relation professionnelle avec celui-ci. Son rôle devient donc de vous représenter au niveau légal et de faire valoir vos intérêts non seulement en cour, mais tout au long du processus de règlement de votre dispute. Un si grand pouvoir est évidemment encadré par la loi afin de vous protéger contre une mauvaise pratique du droit et pour prévenir, d’un autre côté, des poursuites contre les avocats. Voici donc les droits et obligations que la loi prévoit!</p>
<p><strong>Prudence et diligence.</strong> Tout au long du règlement de votre litige, l’avocat que vous engagez aura la tâche d’effectuer des démarches et de prendre des décisions importantes en votre nom. C’est d’ailleurs l’une des raisons principales pourquoi vous l’engagez. Cependant, il ne pourra agir comme bon lui semble sans avoir de comptes à rendre; les avocats ont un devoir d’agir avec prudence et diligence selon les standards d’un avocat raisonnable placé dans les mêmes circonstances. Lorsque cette obligation sera bafouée, l’avocat en question s’exposera à une poursuite, ce qui n’est guère favorable pour qui que ce soit.</p>
<p><strong>Devoir d’information. </strong>Peu importe la taille ou l’importance de la cause que vous remettez entre les mains d’un avocat, celle-ci évoluera en fonction des réponses de la partie adverse et des agissements de votre avocat. Ces changements ne doivent pas être gardés secrets par votre avocat; il a le devoir, encore une fois, de vous tenir au courant du développement du dossier. Notez toutefois qu’il s’agit d’une obligation à deux sens! Il se peut que ce soit de votre côté que la situation évolue et qu’il faille communiquer avec votre avocat pour lui en faire part. En établissant une voie de communication claire et simple, vous assurerez un devoir d’information dument rempli!</p>
<p><strong>Obligation de confidentialité. </strong>Vous et votre avocat serez portés à échanger des informations sensibles et confidentielles. Elles devraient donc être traitées comme telles. Les avocats sont notamment tenus au secret professionnel pour ce qui a trait aux échanges entre vous et lui, mais son obligation de confidentialité va au-delà ce principe déontologique. Il se doit de demeurer discret et d’’assurer un climat de confidentialité à tout moment.</p>
<p><strong>Devoir de loyauté. </strong>Les avocats sont des professionnels partiaux; ils sont payés pour défendre vos intérêts personnels, pas pour jouer à l’arbitre ou à l’entremetteur. Ainsi, ils devraient toujours mettre vos intérêts à l’avant-plan de toute démarche, mais également écouter les demandes que vous formulez.</p>
<p><strong>Bien que l’avocat ait plusieurs obligations à l’égard de son client, ce dernier a également responsabilité envers le professionnel du droit qu’il sollicite. L’une des plus importantes d’entre elles est l’honnêteté. C’est ce qui garantira un règlement efficace du problème et évitera toute confusion possible!</strong></p>
<h2>À quel niveau un avocat peut-il vous conseiller avant votre mariage à Blainville?</h2>
<p>Le mariage est une institution vieille de plusieurs siècles, voire quelques millénaires. Sa forme a varié énormément avec le temps, mais sa forme aujourd’hui prend la forme d’une union moderne emportant des droits et des obligations pour les deux personnes impliquées. Le droit civil québécois ainsi que la loi fédérale canadienne prévoient tous deux des dispositions qualifiées d’ordre public pour ce qui est du mariage. Cela confère un statut presque sacrosaint au mariage et empêche d’écarter la plupart de ces règles par une convention quelconque.</p>
<p><StaticImage alt="conseils-avocats-mariage-regime-matrimonial-patrimoine-familial-blainville" src="../images/conseils-avocats-mariage-regime-matrimonial-patrimoine-familial-blainville.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>C’est pourquoi vous devriez demander quelques conseils à un avocat avant de songer à vous marier! Voici les lois du mariage sur lesquelles un avocat peu vous conseiller avant de dire « Oui, je le veux! ».</strong></p>
<p><strong>La composition de votre patrimoine familial. </strong>Trop peu de gens s’unissant pas les liens du mariage savent ceci : le mariage emporte la composition d’un patrimoine familial qui ne peut <strong>en aucun cas être modifié par contrat</strong>. Celui est composé de quatre catégories de biens, soit les meubles de la maison, les voitures de la famille, les fonds de retraite ainsi que la résidence familiale. La valeur additionnée de tous ces biens compose le patrimoine familial et il s’agit d’une règle d’ordre public, signifiant qu’aucun contrat ne peut la modifier.</p>
<p><strong>Choix du régime matrimonial.</strong> Si le patrimoine familial a un caractère absolu autant au niveau de sa composition que des règles applicables, la même chose ne saurait être dite du régime matrimonial. Cette entité est en fait composée des biens exclus du patrimoine familial qui appartiennent aux deux époux. Ces derniers ont le choix entre 3 régimes prévus par la loi ou encore la possibilité d’en créer un, soit :</p>
<ul>
<li><strong>Société d’acquêts</strong></li>
<li><strong>Séparation de biens</strong></li>
<li><strong>Communauté de biens</strong></li>
</ul>
<p><strong>Conseils sur le type d’union. </strong>La loi québécoise prévoit ne prévoit pas seulement le mariage comme union valide et reconnue. En effet, il y a de cela quelques années, le législateur québécois a créé l’Union civile pour permettre aux personnes inadmissibles au mariage de s’unir selon des formalités plus simples, mais dont les obligations sont les mêmes que pour le mariage. En effet, l’Union civile peut être contractée à partir de l’âge de <strong>18 ans</strong> au lieu de <strong>16 ans</strong> pour le mariage, mais a l’avantage de pouvoir se dissoudre devant notaire et beaucoup plus rapidement qu’un mariage.</p>
<p><strong>Formalités de célébration. </strong>Le mariage est une institution qui se sépare selon deux compétences distinctes, soit la formation et la célébration. La formation est une compétence du gouvernement qui prévoit qu’il faut être âgé d’au moins 16 ans et être capable de consentir librement à l’union pour pouvoir se marier. En revanche, la célébration est une compétence purement provinciale qui régit les règles sur la compétence des célébrants, les avis à publier ainsi que les délais à respecter.</p>
<p>Vérification des éléments administratifs et légaux. Plusieurs démarches d’ordre administratives entourent le mariage. C’est un travail tout de même considérable qui doit être effectué avant le grand jour afin que l’union soit conforme à la loi et incontestable.</p>
<p><strong>Les droits des enfants sont-ils affectés par le type d’union de leurs parents?</strong> Non, le Code civil du Québec prévoit que les enfants ont les mêmes droits, et ce, peu importe qu’ils soient issus d’un mariage, d’une union civile ou d’une union de fait.</p>
<p>Les avocats en droit matrimonial et de la famille se feront un plaisir de vous expliquer les subtilités rattachées à l’institution du mariage, les différents régimes matrimoniaux ainsi que le patrimoine familial. Ce n’est certainement pas une précaution à négliger, croyez-nous!</p>
<h2>Les services d’avocats en matière de divorce</h2>
<p>Pour le meilleur et pour le pire n’a malheureusement plus le même niveau de dévouement qu’à une époque fort lointaine. Les divorces sont effectivement très fréquents au Québec et ils nécessitent inévitablement l’intervention d’un avocat, surtout lorsque des enfants sont affectés par la demande. Heureusement, la popularité regrettable du divorce fait en sorte qu’il s’agit d’un domaine de droit regorgeant d’avocats compétents. Pour ceux d’entre vous qui se retrouvent dans une situation de divorce, voici ce qu’un avocat fera pour régler le tout!</p>
<p><strong>Réconciliation et médiation. </strong>Comme le divorce a pour effet de rompre les liens du mariage et de dissoudre l’union, un juge vérifiera toujours s’il existe une possibilité de réconciliation entre les deux époux. Lorsque ceux-ci se rendront en cour et que le juge aura vent d’une telle possibilité, il pourra même reporter l’audience pour que les conjoints tentent de réparer les ponts.</p>
<p>Lorsque la réconciliation aura finalement fait chou blanc, il restera toujours la possibilité d’entamer une <strong>médiation familiale</strong>. Cette initiative ne vise plus à sauver l’union, mais plutôt à la terminer à l’amiable en réglant les divers aspects de la séparation d’un commun accord, sans qu’un juge n’ait à trancher le débat. Cela est toujours recommandé lorsque des enfants sont au cœur de la séparation.</p>
<p>Les sujets tels que la garde, le montant de la pension, les droits de visite et autres stipulations essentielles seront réglées lors de cette conférence. Le projet sera soumis au juge lors du règlement du divorce. D’ailleurs, les avocats en droit de la famille sont parfois qualifiés en tant que médiateur et sont capables d’administrer une telle séance!<strong>
</strong></p>
<p><StaticImage alt="mediation-familiale-divorce-mesures-accessoires-meilleurs-avocats-blainville" src="../images/mediation-familiale-divorce-mesures-accessoires-meilleurs-avocats-blainville.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p>Élaboration des motifs de divorce. En théorie, les conjoints se marient pour le reste de leur vie, advienne que pourra. C’est la raison pour laquelle la loi exige des motifs précis pour qu’un divorce soit prononcé. Il faudra prouver ceux-ci au moment de se présenter devant le juge, sans quoi le jugement de divorce ne pourra être prononcé. Au Canada, les causes de divorce acceptées sont :</p>
<ul>
<li>L’absence de vie commune pendant 1 an.</li>
<li>L’adultère de l’un des conjoints.</li>
<li>Une cruauté physique ou psychologique infligée par l’un des conjoints.</li>
</ul>
<p>Le principal motif de divorce utilisé aujourd’hui est celui de l’absence de vie commune pendant 1 an. Il ne demande que peu d’efforts et il se prouve très facilement, soit par le changement d’adresse et, lorsqu’ambigu, par témoignage.</p>
<p>Présentation d’une demande conjointe. Il est aussi possible de présenter une demande conjointe de divorce dans laquelle les deux conjoints se montrent d’accord pour mettre fin à l’union. Ce projet vise principalement à régler les mesures accessoires à l’amiable pour éviter que le juge ait à le faire et surtout pour prévenir que le conflit ne s’éternise. En l’absence d’une demande conjointe, les parties devront se munir d’un avocat pour faire valoir leurs arguments respectifs sur les mesures accessoires.</p>
<p>Mesures accessoires au divorce. Quelles sont les mesures accessoires en matière de divorce? Elles comportent les dispositions relatives à la garde des enfants, la pension alimentaire, les modalités d’exercice de l’autorité parentale, la pension entre conjoints, la rupture des legs, le droit des grands-parents, etc.</p>
<p>Si le couple ne s’entend pas sur les modalités des mesures accessoires, c’est le juge qui aura le fardeau de trancher. Ces jugements comblent rarement les parties de bonheur, car ils réalisent un compromis qui laisse un goût amer dans la bouche de toutes les parties impliquées.</p>
<p><strong>C’est ici l’utilité d’engager un avocat! En faisant affaire avec un expert du droit de la famille, vous mettez toutes les chances de votre côté de conclure une entente avec votre prochainement ex-conjoint et de présenter une demande conjointe. Ne laissez pas le juge dicter l’issue de votre divorce!</strong></p>
<h2>Poursuite en divorce et divorce à l’amiable : quelle différence avec un avocat à Blainville?</h2>
<p>Au moment de rompre les liens sacrés du mariage par l’entremise du divorce, deux options s’offriront à vous, soit la poursuite en divorce ou le divorce à l’amiable sur projet d’accord. La première option est celle qu’on pourrait qualifier de plus « traditionnelle », car il s’agit de la situation typique des conjoints qui s’affrontent en cour pour obtenir la garde des enfants et la moitié de la maison.</p>
<p>En revanche, le divorce à l’amiable est une avenue beaucoup moins conflictuelle, car elle implique que les conjoints s’entendent sur les modalités du divorce de « A à Z ». Ce faisant, il est inutile que les conjoints engagent chacun un avocat pour tenter d’obtenir gain de cause en cour et il ne restera qu’à prévoir par écrit les termes de l’ente pour la soumettre au juge.</p>
<p><strong>Quels sont les avantages du divorce à l’amiable? </strong>L’économie de temps est l’un des avantages considérables du divorce sur projet d’accord. En effet, alors que le divorce traditionnel implique des négociations exhaustives entre les conjoints pour tenter de trouver un terrain d’entente tout en respectant les droits de chacun. Lorsque la discorde se fait ressentir, les avocats sont forcés de passer plus de temps sur le dossier, ce qui implique des honoraires additionnels à défrayer.</p>
<p>Les économies d’argent sont également considérables lorsque vous optez pour le divorce à l’amiable plutôt que pour la poursuite en divorce traditionnelle. Ce sont plusieurs milliers de dollars que vous pourriez épargner en choisissant l’avenue de l’entente.</p>
<p><strong>Est-il nécessaire d’engager un avocat pour régler un divorce à l’amiable? </strong>Bien qu’il soit possible de vous représenter seul comme pour toute démarche légale, le divorce à l’amiable implique tout de même de respecter les nombreuses lois du divorce et de préparer un projet de divorce devant le juge. Pour que celui constate un projet bien préparé, complet et sans failles, il vaut mieux faire affaire avec un avocat en droit de la famille pour préparer cet accord.</p>
<p><strong>Le divorce à l’amiable change-t-il les règles du divorce?</strong> Absolument pas! Les conditions du divorce demeurent les mêmes et la seule différence reste celle du consentement entre les conjoints sur l’entièreté des modalités du divorce. En effet, même les mesures accessoires, les modalités de la garde d’enfants et de la pension alimentaire applicable doivent avoir convenus au moment de déposer le projet.</p>
<p><strong>Épargnez-vous les frais d’un procès et faites confiance aux avocats en droit de la famille référés par Soumissions Avocat!</strong></p>
<h2>Quels sont les avantages d’opter pour la médiation familiale lors d’un divorce?</h2>
<p>Comme vous le savez, le divorce traditionnel coute nettement plus cher que le divorce à l’amiable, en plus de laisser les conjoints avec un gout amer une fois la procédure terminée. Cela ne sert les intérêts de personne et c’est pourquoi la médiation s’avère d’autant plus pertinente en situation de divorce.</p>
<p>La première raison de consulter un médiateur en cas de divorce est de préparer une <strong>entente équitable et juste</strong>. Même si la loi et le régime matrimonial en place dictent la plupart des procédures à suivre pour régler le divorce, il reste tout de même du jeu pour négocier, notamment en termes de garde d’enfants et de pension alimentaire. Comme les gens ayant une expérience concrète du divorce sont peu nombreux, les connaissances du médiateur viennent à point lors des séances de médiation.</p>
<p>Le <strong>respect des droits des enfants</strong> est une autre raison d’entreprendre des démarches de médiation. Bien que ce soit les parents qui détiennent le contrôle de la demande en divorce, ce sont les enfants qui en paient la majorité des frais, surtout lorsque leurs intérêts sont relayés au second rang. Le médiateur familial se chargera de ramener le bien-être de votre progéniture à l’avant-plan des pourparlers!</p>
<p>L’efficacité de la communication est un autre avantage considérable de la médiation familiale. Comme un divorce est haut en émotions, il peut devenir difficile de communiquer adéquatement avec son futur ex-conjoint. L’intervention du tiers médiateur viendra donner un coup de pouce fort utile!</p>
<h2>Les conséquences d’un divorce avec un avocat à Blainville</h2>
<p>Le divorce consiste en la rupture des liens du mariage et, par conséquent, en la dissolution des régimes matrimoniaux y étant rattachés. Les conséquences d’un divorce sont toutefois plus importantes que cela et connaissent de vastes répercussions d’ordre financières, familiales et successorales.</p>
<p>Rien ne sert de paniquer, un avocat en droit de la famille se fera un plaisir de vous expliquer en détail les conséquences de votre divorce. Pour vous donner une idée, voici les principales conséquences auxquelles vous pouvez vous attendre!</p>
<p><StaticImage alt="Les conséquences d’un divorce avec un avocat à Blainville" src="../images/avocat-mediation-familiale-divorce-amiable-blainville.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
Le partage du patrimoine familial : </strong>Tel qu’expliqué ci-haut, le mariage engendre la création d’un patrimoine familial composé de quatre catégories de biens. Au divorce, ce patrimoine se partage également indépendamment de quel conjoint en était réellement propriétaire. Il faudra calculer adéquatement la valeur de ce patrimoine et identifier précisément quels biens y figurent, chose que votre avocat ne manquera pas de faire.</p>
<p><strong>Le partage du régime matrimonial :</strong> Que vous ayez opté pour la société d’acquêts ou la séparation de biens, il faudra inévitablement partager ces biens selon les modalités stipulées au contrat de mariage. Cela peut paraître simple, mais bien des gens ignorent le régime matrimonial qu’ils ont choisi jusqu’au jour où ils songent à divorcer.</p>
<p><strong>Prestation compensatoire :</strong> Le principe derrière la prestation compensatoire est qu’il arrive fréquemment qu’un époux contribue à l’enrichissement de l’autre par ses efforts ou ses services rendus pendant le mariage. C’est le cas notamment d’un(e) conjoint(e) qui aide son époux à développer une entreprise sans recevoir de salaire. Au moment du divorce, ce conjoint sera en droit de réclamer une indemnité sous forme de prestation compensatoire à l’autre.</p>
<p><strong>Pension alimentaire :</strong> Lors d’un divorce, le versement d’une pension alimentaire peut aussi bien être versé à l’ex-conjoint qu’au bénéfice des enfants. Qui décidera du bénéficiaire de la pension? Sans entente entre les époux, ce sera le tribunal qui établira le montant et les modalités de la pension alimentaire.</p>
<p><strong>Garde des enfants :</strong> Le divorce étant synonyme de séparation, il est donc nécessaire de prévoir les modalités concernant la garde des enfants. Les conjoints sont libres de s’entendre entre eux sur les modalités de celle-ci et ce n’est qu’en cas de désaccord que le tribunal interviendra.</p>
<p><strong>Le divorce est lourd de conséquences, mais celles-ci n’ont pas à être négatives pour autant. La meilleure façon rendre votre divorce plus facile, c’est de vous entourer d’un expert comme l’avocat en droit de la famille dès le départ.</strong></p>
<p><strong> </strong></p>
<h2>Quel est le rôle du Barreau du Québec?</h2>
<p>En tant que personne étrangère à la profession juridique et à la profession d’avocat dans son ensemble, vous ignorez probablement ce que fait le Barreau du Québec. Vous avez une vague idée qu’il s’agit de l’ordre des avocats du Québec et que la réussite de son examen est nécessaire à l’obtention du titre d’avocat, mais au-delà de cela, vous ne connaissez pas réellement son rôle? Sachez que le Barreau cherche à protéger les intérêts de quiconque sollicite les services d’avocats et voici comment!</p>
<p><strong>Protection du public.</strong> Le Barreau surveille activement la profession d’avocat afin de protéger la population contre les charlatans de la profession juridique. En effet, en surveillant les personnes offrant des services sous le titre d’avocat, mais également en prenant très sérieusement les plaintes formulées contre ces professionnels, le Barreau assure une protection pour les gens sollicitant un avocat.</p>
<p><strong>Surveillance de la profession. </strong>Pour protéger le public, le Barreau surveille de près les avocats. Cela assure que la profession maintient des standards adéquats et qu’elle continue d’évoluer dans une voie qui aide proactivement la société québécoise.</p>
<p><strong>Application de sanctions professionnelles.</strong> En constatant une inconduite de la part de l’un de ses membres, le Barreau du Québec a le pouvoir de sanctionner et même de radier des membres avocats. Cette radiation peut être temporaire ou encore permanente lorsque l’inconduite sera d’une gravité notoire.</p>
<p><strong>Comme tous les ordres professionnels, le Barreau est une entité qui vise à préserver la qualité des services juridiques offerts à la population par un mécanisme d’étroite surveillance. Si vous avez des doutes sur le service offert par un avocat ou vous pensez avoir été victime d’une faute de la part d’un avocat, n’hésitez pas à contacter le Barreau!</strong></p>
<p><strong> </strong></p>
<h2>Avocat spécialiste ou généraliste : choisir parmi les nombreux domaines à Blainville!</h2>
<p>Dans la pratique du droit, les professionnels font souvent la distinction entre les avocats spécialistes et les généralistes. Alors que ces premiers se concentrent dans quelques domaines de droit seulement pour offrir une expertise pointue, ces derniers préfèrent œuvrer dans plusieurs domaines de droit à la fois. La complexité de la situation est évidemment ce qui dictera quel type d’avocat vous avez besoin. Pour vous aider à déterminer vos besoins, se familiariser avec les différents domaines de droit pourrait grandement vous aider!</p>
<p><StaticImage alt="avocat-generaliste-specialiste-domaines-droit-famille-affaires-blainville" src="../images/avocat-generaliste-specialiste-domaines-droit-famille-affaires-blainville.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
Affaires : </strong>Grandes entreprises, PME, gestionnaires et gens d’affaires en général peuvent tous bénéficier des services d’un avocat en droit des affaires! À juste titre, les avocats en droit des affaires sont compétents pour effectuer toutes les démarches légales relatives au fonctionnement et la mise en place d’une entreprise. Ces services comprennent notamment l’incorporation, la rédaction d’une convention d’actionnaires, les litiges commerciaux ainsi que les disputes entre actionnaires.</p>
<p><strong>Immobilier : </strong>Les disputes entre des propriétaires sont monnaie courante au Québec. Il s’agit d’une relation complexe entre ces deux personnes qui est toujours délicate à gérer lorsqu’un litige se pointe le bout du nez. Que vous soyez du côté du propriétaire ou du locataire, un avocat en droit immobilier peut toujours vous aider à faire valoir vos droits!</p>
<p><strong>Contrats :</strong> Les contrats font partie de la vie courante au point tel que la plupart des gens en concluent sans même s’en rendre compte. Le simple achat d’une bouteille d’eau au dépanneur forme un contrat de consommation en quelques secondes seulement. Pour les contrats d’une plus grande importance, il se peut que des conséquences imprévues surviennent.</p>
<p>C’est notamment le cas lorsque la contrepartie contractuelle refuse de respecter ses engagements. Il vous faudra donc, en pareilles circonstances, faire appel à un avocat pour forcer l’exécution du contrat!</p>
<p><strong>Responsabilité civile :</strong> La loi impose un devoir de diligence à tous les citoyens à l’égard de leurs homologues, et ce, qu’ils soient dans une relation contractuelle ou non. C’est ce que l’on qualifie de responsabilité civile et elle vise à dédommager les gens victimes de la négligence d’une tierce personne. Ce recours légal exige de faire la preuve d’une faute, ce qui n’est pas chose facile sans l’assistance d’un avocat.</p>
<p><strong>Criminel et pénal :</strong> Il ne suffit que d’un seul écart de conduite au mauvais endroit au mauvais moment pour qu’une accusation criminelle ne vous tomber sur la tête. De tous les gens qui commettent des crimes au quotidien sans se faire prendre, il a fallu que vous soyez celui ou celle du lot qui s’est fait prendre la main dans le sac. Que vous admettiez votre culpabilité ou non, il vous faut l’aide d’un avocat au plus vite pour éviter une sentence démesurée ou une procédure injuste.</p>
<h2>Avez-vous automatiquement le droit de poursuivre en cour?</h2>
<p>Les cours de justice sont des lieux très occupés de la branche judiciaire québécoise. Le nombre de gens tentant d’obtenir justice est élevé et c’est pourquoi vous avez besoin d’une véritable raison pour être en mesure d’accéder aux tribunaux. Cette raison est qualifiée en droit « d’intérêt pour agir en justice ». Cet intérêt doit avoir quatre caractéristiques pour qu’un juge accepte d’entendre votre poursuite, soit :</p>
<p><strong>Juridique : </strong>L’intérêt soulevé doit être juridique. Cela signifie que votre cause doit soulever une question de droit et non simplement une question de moralité ou d’éthique. Ainsi, votre chicane de famille est peut-être très triste et frustrante, mais à moins qu’elle ne soulève un point légal, elle ne pourra pas être entendue.</p>
<p><strong>Personnel : </strong>Vous devez être vous-mêmes concernés par la cause que vous soumettez en cour. L’injustice de la situation d’une autre personne ne vous concerne pas au sens du droit; vous agissez pour vous-mêmes seulement. La seule exception à ce principe est lorsque la violation concerne une règle d’ordre public.</p>
<p><strong>Né :</strong> Pour qu’un intérêt soit « né », il doit exister et avoir pris naissance au moment de présenter le litige en cour. Vous ne pouvez pas poursuivre pour un préjudice futur ou imaginé.</p>
<p><strong>Actuel :</strong> En plus de rassembler les trois caractéristiques mentionnées ci-haut, le litige soumis doit également être contemporain et être toujours pertinent à entendre au moment oà il est soumis en cour.</p>
<p><strong>Vous n’êtes pas certain que votre cause est admissible en cour et que vous avez un intérêt juridique suffisant pour agir en justice? Les avocats de Blainville détermineront la qualité de votre intérêt sans problème!</strong></p>
<h2>Questions fréquentes pour les avocats de Blainville!</h2>
<p>Pour guider votre démarche de recherche et vous aider à trouver un avocat qui répond à vos attentes à tous les niveaux, nous vous proposons de suivre nos conseils et de poser des questions à votre juriste avant de signer une entente avec lui!</p>
<p><StaticImage alt="questions-frequentes-honoraires-resolution-differend-avocat-blainville" src="../images/questions-frequentes-honoraires-resolution-differend-avocat-blainville.jpeg" placeholder="blurred" imgStyle={{ maxWidth: '80%', margin: '0 auto' }}></StaticImage></p>
<p><strong>
À quoi ressemblent vos options alternatives pour régler votre litige?</strong></p>
<p>Un procès, c’est long et ça coûte cher! C’est pourquoi vous voudrez toujours considérer vos options alternatives et examiner les possibilités de régler votre dossier façon privé, sans l’intervention de la justice publique.</p>
<p><strong>Quelles sont les lois particulières qui s’appliquent à votre dossier?</strong></p>
<p>Il y a le droit commun et ensuite, il y a les lois particulières. Ces dernières peuvent rendre certains recours très difficiles, voire presque impossibles à gagner. En demandant à votre avocat quel est le régime applicable à votre situation, vous aurez une bonne idée de vos chances de gagner.</p>
<p><strong>Y a-t-il des façons dont vous pouvez assister votre avocat?</strong></p>
<p>Les avocats doivent effectuer plusieurs vérifications pour régler votre dossier, surtout au niveau des documents de preuve, des factures, relevés, etc. En lui proposant de l’aider, vous lui ferez sauver du temps et comme le temps, c’est de l’argent, vous sauverez quelques sous en plus!</p>
<p><strong>En quoi son expertise légale vous est-elle utile pour votre dossier?</strong></p>
<p>Comme tous les avocats ne sont pas créés égaux et n’ont pas les mêmes compétences, questionner celui que vous songez engager sur ce qui fait de lui l’expert par excellence servira assurément vos intérêts.</p>
<h2>Soumissions Avocat s’occupe de trouver l’avocat qu’il vous fait à Blainville!</h2>
<p>À Blainville, les avocats sont parmi les plus qualifiés au Québec pour vous offrir des services juridiques de qualité et régler votre litige efficacement et au moindre coût. Il suffit de les contacter pour que leur expertise soit mise à votre service.</p>
<p><strong>Comment s’y prendre pour entrer en contact avec les avocats de Blainville? Il suffit de remplir le formulaire de soumissions pour être mis en relation avec 3 des meilleurs avocats de notre réseau.</strong></p>
<p><strong>Notre service est tout à fait gratuit, ne vous engage à rien et assure un règlement rapide de votre problème légal! Pourquoi hésiter, contactez-nous!</strong></p>
<p>Dernière modification: 9 août 2020</p>
    </SeoPage>
)
export default ContentPage7
  